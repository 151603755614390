import { Query } from '@directus/types'
import { FaqTypeDTO, FaqTypeEntity } from './faq.type'
import { consola } from 'consola'
import { useFaqUtils } from './faq.utils'

export const useFaq = () => {
    const { getItems, getItemById } = useDirectusItems()
    const { generateFaqDto } = useFaqUtils()

    const getFaqIndex = async (options?: { query?: { limit?: any } }) => {
        return getItems({
            collection: 'faqs',
            params: {
                filter: {
                    status: {
                        _eq: 'published'
                    }
                },
                fields: [
                    'id', 'status',
                    'date_created',
                    'sort',
                    'title',
                    'content'
                ],
                sort: ['-date_created'],
                limit: +options?.query?.limit || -1
            }
        })
            .then((data) => data.map((item: any) => generateFaqDto(item)))

            .catch((e) => {
                consola.error('ERR getFaqIndex : ', e.message)
                consola.warn(e)
            })

    }

    return {
        getFaqIndex,
    }
}
