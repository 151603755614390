<template>
    <div class="relative shadow-custom-input w-full" v-on-click-outside="onClickOutsideHandler">
        <label for="province" class="text-sm font-medium text-blacks-70">{{ title }}</label>
        <div class="w-full relative flex items-center mt-1.5">
            <input
                type="text"
                id="province"
                @focus="statusDropdown = true"
                class="placeholder-text-blacks-30 w-full custom-input"
                :class="
                    checkEmpty && !itemModel?.title
                        ? '!placeholder:text-error-01/80 !border-error-01 !text-error-01'
                        : ''
                "
                :value="itemModel?.title"
                @input="$emit('update:itemModel', { title: $event.target.value })"
                autocomplete="off"
                :placeholder="placeholder"
            />
            <i
                v-if="!itemModel?.title"
                @click="statusDropdown = true"
                class="i-ic:outline-keyboard-arrow-down absolute right-3.5 text-blacks-70 cursor-pointer"
            ></i>
            <i
                v-else
                @click="$emit('update:itemModel', { title: '' }), (statusDropdown = true)"
                class="i-ic:baseline-cancel absolute right-3.5 text-blacks-10 hover:text-blacks-30 duration-200 cursor-pointer"
            ></i>
        </div>

        <Transition name="nestedSelect">
            <div
                v-if="statusDropdown && data && data.length > 0"
                class="absolute top-full mt-2 w-full rounded-md z-10 left-0 max-h-80 overflow-auto custom-scrollbar bg-white b-1 shadow-custom"
            >
                <div
                    v-for="item in data"
                    :key="item.id"
                    @click="$emit('update:itemModel', item), (statusDropdown = false)"
                    :class="itemModel?.id == item?.id ? 'bg-blacks-90 text-white' : ''"
                    class="px-4 py-2 hover:bg-blacks-90 hover:text-white text-sm"
                >
                    {{ item.title }}
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
defineProps({
    title: {
        type: String
    },
    placeholder: {
        type: String
    },
    data: {
        type: Array as any
    },
    itemModel: {
        type: Object as any
    },
    checkEmpty: {
        type: Boolean as any
    }
})
defineEmits(['update:itemModel', 'update:checkEmpty'])
const statusDropdown = ref(false)
const onClickOutsideHandler = () => {
    statusDropdown.value = false
}
const handleCloseDropdown = (status: boolean) => {
    statusDropdown.value = status
}
</script>

<style scoped>
.custom-input {
    @apply w-full py-3 px-3.5 b-1 b-blacks-10;
}
.shadow-custom {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.shadow-custom-input {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.nestedSelect-enter-active,
.nestedSelect-leave-active {
    transition: all 0.15s ease;
}

/* delay leave of parent element */
.nestedSelect-leave-active {
}

.nestedSelect-enter-from,
.nestedSelect-leave-to {
    transform: translateY(60px);
    opacity: 0;
}
</style>
