import { FaqTypeDTO, FaqTypeEntity } from './faq.type';
import { consola } from 'consola'

export const useFaqUtils = () => {

    const generateFaqDto = (item: FaqTypeEntity): FaqTypeDTO | any => {
        try {
            let output = {
                ...item
            }
            return output
        } catch (e: any) {
            consola.error('ERR generateFaqDto : ', e)
            return {}
        }
    }

    return {
        generateFaqDto
    }
}
