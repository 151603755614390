<template>
    <section>
        <div class="container">
            <v-content option_key="support_faq_title" />

            <div class="my-10 lg:my-16">
                <div v-auto-animate v-for="item in faq" :key="item.id" class="">
                    <div class="w-full h-1px bg-blacks-10 my-5"></div>
                    <div
                        @click="current = current === item.id ? false : item.id"
                        class="flex justify-between cursor-pointer gap-5"
                    >
                        <p class="text-blacks-100 font-medium text-lg">{{ item?.title }}</p>
                        <i v-if="current == item.id" class="i-ic:baseline-minus w-5 h-7.5 flex-none"></i>
                        <i v-else class="i-ic:baseline-add w-5 h-7.5 flex-none"></i>
                    </div>
                    <div
                        v-if="item.id == current"
                        class="mt-5 text-sm lg:text-base text-blacks-90 content-faq"
                        v-html="item?.content"
                    ></div>
                </div>
            </div>
            <GlobalWrapperContactFooter />
        </div>
    </section>
</template>

<script setup lang="ts">
const { getFaqIndex } = useFaq()
const faq = ref([])
const current = ref(faq.value[0]?.id)
onMounted(async () => {
    faq.value = await getFaqIndex()
})
</script>

<style>
.content-faq ul {
    @apply list-disc ml-4;
}
</style>
