<template>
    <section>
        <div class="container">
            <div class="w-full lg:w-1/2 mx-auto my-15 lg:my-24">
                <v-content class="lg:text-lg text-blacks-90" option_key="support_form_title"></v-content>
                <form @submit.prevent="sendForm" class="placeholder-addCart space-y-4 mt-8">
                    <div class="relative flex flex-col sm:flex-row items-center gap-3 w-full">
                        <div class="w-full">
                            <label
                                for="contact_support_first_name"
                                :class="errorFirstName ? '!text-error-01' : ''"
                                class="text-sm text-blacks-70 font-medium"
                                >Tên</label
                            >
                            <input
                                type="text"
                                name="first_name"
                                id="contact_support_first_name"
                                :class="
                                    errorFirstName ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''
                                "
                                class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                                v-model="infoUser.first_name"
                                @input="infoUser.first_name = capitalizeFirstLetterOfWords(infoUser.first_name)"
                                placeholder="Tên"
                            />
                        </div>
                        <div class="w-full">
                            <label for="contact_support_last_name" class="text-sm text-blacks-70 font-medium">Họ</label>
                            <input
                                type="text"
                                name="last_name"
                                id="contact_support_last_name"
                                class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                                v-model="infoUser.last_name"
                                @input="infoUser.last_name = capitalizeFirstLetterOfWords(infoUser.last_name)"
                                placeholder="Họ"
                            />
                        </div>
                    </div>
                    <div class="relative">
                        <label
                            for="contact_support_email"
                            :class="errorEmail ? '!text-error-01' : ''"
                            class="text-sm text-blacks-70 font-medium"
                            >Email</label
                        >
                        <input
                            type="text"
                            name="email"
                            id="contact_support_email"
                            :class="errorEmail ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
                            class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                            v-model="infoUser.email"
                            placeholder="email@company.com"
                        />
                    </div>
                    <div class="relative">
                        <label
                            for="contact_support_phone"
                            :class="errorPhone ? '!text-error-01' : ''"
                            class="text-sm text-blacks-70 font-medium"
                            >Số điện thoại</label
                        >
                        <input
                            type="text"
                            name="phone"
                            id="contact_support_phone"
                            :class="errorPhone ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
                            class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                            v-model="infoUser.phone"
                            placeholder="(+84) 0000 00000"
                        />
                    </div>
                    <div class="shadow-custom-input w-full">
                        <label
                            for="province"
                            :class="errorProvince ? '!text-error-01' : ''"
                            class="text-sm font-medium text-blacks-70"
                            >Tỉnh/Thành phố</label
                        >
                        <Selection
                            v-model:itemModel="proviceValue"
                            v-model:checkEmpty="errorProvince"
                            :data="dataProvince"
                            placeholder="Chọn tỉnh/thành phố"
                        />
                    </div>
                    <div class="shadow-custom-input w-full">
                        <label
                            for="province"
                            :class="errorDevice ? '!text-error-01' : ''"
                            class="text-sm font-medium text-blacks-70"
                            >Loại thiết bị</label
                        >
                        <SelectionDevice
                            v-model:itemModel="deviceValue"
                            v-model:checkEmpty="errorDevice"
                            :data="dataDevice"
                            placeholder="Chọn thiết bị"
                        />
                    </div>
                    <div class="">
                        <label class="flex items-start gap-3 checkbox-custom cursor-pointer">
                            <input
                                type="checkbox"
                                name=""
                                v-model="isChecked"
                                :class="errorChecked ? '!border-red-500' : ''"
                                class="select-agree flex-none cursor-pointer"
                            />
                            <p :class="errorChecked ? 'text-red-500' : ''" class="text-sm xl:text-base text-blacks-70">
                                Bạn đồng ý với <a href="#" class="underline">chính sách bảo mật</a> của chúng tôi.
                            </p>
                        </label>
                    </div>
                    <button
                        class="btn-effect-dark w-full b-1 b-blacks-90 text-white bg-blacks-100 text-lg font-medium py-3 xl:py-5 text-center cursor-pointer block"
                    >
                        <p v-if="!loading">Yêu cầu hỗ trợ</p>
                        <GlobalLoadingSearch v-else class="mx-auto" />
                    </button>
                    <div v-if="statusSuccessForm" class="flex flex-col items-center gap-2">
                        <i class="i-ic:baseline-check-circle-outline text-4xl bg-[#01AB56]"></i>
                        <p class="text-xl font-bold text-red-500">Gửi yêu cầu thành công</p>
                        <p class="text-blacks-50 text-center text-lg">
                            Chúng tôi sẽ liên hệ bạn trong thời gian sớm nhất !
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useReCaptcha } from 'vue-recaptcha-v3'
import Selection from '../placeToBuy/Selection'
import SelectionDevice from './Selection'
const globalSettings = inject('globalSettings')

const { getProductCategories } = useProducts(globalSettings)
const dataProvince = ref([])
const dataProvinceDefault = ref()
const proviceValue = ref({})
const dataDevice = ref([])
const dataDeviceDefault = ref([])
const deviceValue = ref({})
const provice = getProvince()
const loading = ref(false)
watchDebounced(
    () => proviceValue.value.name,
    (newValue) => {
        checkProvince()
        if (!newValue) {
            return (dataProvince.value = dataProvinceDefault.value)
        }
        dataProvince.value = dataProvinceDefault.value.filter((item: any) => {
            return item.name.toLowerCase().includes(newValue.toLowerCase())
        })
    },
    { debounce: 500 }
)
watchDebounced(
    () => deviceValue.value.title,
    (newValue) => {
        checkDevice()

        if (!newValue) {
            return (dataDevice.value = dataDeviceDefault.value)
        }
        dataDevice.value = dataDeviceDefault.value.filter((item: any) => {
            return item.title.toLowerCase().includes(newValue.toLowerCase())
        })
    },
    { debounce: 500 }
)
const infoUser = ref({
    phone: '',
    first_name: '',
    last_name: '',
    email: ''
})
const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
    // optional you can await for the reCaptcha load
    await recaptchaInstance?.recaptchaLoaded()

    // get the token, a custom action could be added as argument to the method
    const token = await recaptchaInstance?.executeRecaptcha('login')

    return token
}

const errorPhone = ref(false)
const errorFirstName = ref(false)
const errorLastName = ref(false)
const errorEmail = ref(false)
const errorProvince = ref(false)
const errorDevice = ref(false)
const errorChecked = ref(false)
const isChecked = ref()
const convertedPhoneNumber = () => {
    if (infoUser.value.phone !== '' && infoUser.value.phone[0] === '0') {
        var convertedPhoneNumber = infoUser.value.phone?.substring(1)
        infoUser.value.phone = '+84' + convertedPhoneNumber
    }
}
const checkPhone = () => {
    const vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
    if (infoUser.value.phone) {
        const convertedPhoneNumber = infoUser.value.phone.replace(/^\+84/, '0')
        if (vnf_regex.test(convertedPhoneNumber)) {
            errorPhone.value = false
        } else {
            errorPhone.value = true
        }
    } else {
        errorPhone.value = true
    }
}
const checkName = () => {
    if (infoUser.value.first_name) {
        errorFirstName.value = false
    } else {
        errorFirstName.value = true
    }
}
const checkProvince = () => {
    if (proviceValue.value.name) {
        errorProvince.value = false
    } else {
        errorProvince.value = true
    }
}
const checkDevice = () => {
    if (deviceValue.value.id) {
        errorDevice.value = false
    } else {
        errorDevice.value = true
    }
}

const checkEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (emailRegex.test(infoUser.value.email)) {
        errorEmail.value = false
    } else {
        errorEmail.value = true
    }
}

const checkChecked = () => {
    if (isChecked.value) {
        errorChecked.value = false
    } else {
        errorChecked.value = true
    }
}
watch(
    () => isChecked.value,
    () => {
        checkChecked()
    }
)

const statusSuccessForm = ref(false)
const statusFailForm = ref(false)

const sendForm = async (): Promise<void> => {
    checkPhone()
    checkDevice()
    checkName()
    checkEmail()
    checkProvince()
    checkChecked()
    if (
        !errorFirstName.value &&
        !errorPhone.value &&
        !errorEmail.value &&
        !errorChecked.value &&
        !errorDevice.value &&
        !errorProvince.value
    ) {
        loading.value = true

        statusSuccessForm.value = false
        const token = await recaptcha()
        fetch(`/api/support?response=${token}`, {
            method: 'POST',
            body: JSON.stringify({
                ...infoUser.value,
                province: proviceValue.value?.name,
                product_category: deviceValue.value?.id
            })
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.code == 201) {
                    loading.value = false
                    statusFailForm.value = false
                    statusSuccessForm.value = true
                    setTimeout(() => {
                        statusSuccessForm.value = false
                    }, 10000)

                    infoUser.value = {
                        phone: '',
                        first_name: '',
                        last_name: '',
                        email: ''
                    }

                    errorChecked.value = false
                } else {
                    statusFailForm.value = true
                }
            })

            .catch((error) => {
                console.log(error)
                statusFailForm.value = true
            })
    }
}
onMounted(async () => {
    dataProvince.value = provice
    dataProvinceDefault.value = provice
    await getProductCategories().then((response) => {
        dataDevice.value = response
        dataDeviceDefault.value = response
    })
})
</script>

<style></style>
