<template>
    <GlobalBannerEffect :breadcrumb="breadcrumb" />
    <SectionContact />
    <SectionFAQ />
</template>

<script setup lang="ts">
import SectionContact from './SectionContact'
import SectionFAQ from './SectionFAQ'
const breadcrumb = ref([
    {
        title: 'Trang chủ',
        url: '/'
    },
    {
        title: 'Hỗ trợ lắp đặt'
    }
])
useDirectusCollectionSeo('setting_support', {})
</script>

<style></style>
